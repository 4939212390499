<template>
	<div>
		<div class="home-header ">
			<div class="mode-center">
				<div
					class=" grid grid-cols-5 gap-24 mx900:grid-cols-1 pt-170 pb-16 mx640:pt-140 mx900:gap-0"
				>
					<div class="anime-hover text-pine col-span-3">
						<div
							class="mb-8 h1-text font-national mx640:text-9"
							v-if="data_hero.title"
						>
							{{ data_hero.title }}
						</div>
						<div
							class="pb-8 text-24 font-tiempos_r mx640:text-4.5 section_text"
							v-if="data_hero.text"
							v-html="data_hero.text"
						></div>
					</div>
					<div v-if="data_hero.image" class="col-span-2">
						<img class="w-full" :src="data_hero.image.md" />
					</div>
					<div v-if="data_hero.image == null" class="col-span-2">
						<img
							class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
							src="@/assets/logo-huge.svg"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-verbena sun-big-up">
			<div class="mode-center text-pine pb-280 mx640:pb-16">
				<h1
					class="pt-120 pb-24 h1-text mx640:text-9"
					v-if="data_what_we_do.title"
				>
					{{ data_what_we_do.title }}
				</h1>
				<Step
					v-for="step in data_what_we_do.list"
					:key="step.id"
					:step="step"
				/>
			</div>
		</div>

		<div class="relative sun-big-down">
			<div class="mode-center">
				<div
					class="w-11/12 text-pine grid grid-cols-8 gap-8 pt-230 mx640:pt-16 pb-85 mx640:grid-cols-1 items-baseline"
				>
					<h1 class="h1-text col-span-3 mx640:text-9" v-if="data_funds.title">
						{{ data_funds.title }}
					</h1>
					<div
						class="font-tiempos_r col-span-5 section_text section_text_bread anime-hover"
						v-if="data_funds.text"
						v-html="data_funds.text"
					></div>
				</div>
			</div>
		</div>

		<div class="bg-pine">
			<div
				class="pt-120 pb-100 ml-5% grid grid-cols-5 gap-10 mx640:grid-cols-1 w-105% mx900:grid-cols-4 mx900:w-90%"
			>
				<div class=" text-white max-w-510 col-span-2">
					<div
						class="h-full mx640:mt-0 flex flex-col justify-between min-h-520 mx640:min-h-0"
					>
						<div class="">
							<div
								class="text-40 font-national mx1080:text-4 mx640:text-6 pb-8"
							>
								{{ fundData.title }}
							</div>
							<div
								class="text-24 font-tiempos_r leading-9 mx1080:text-2 mx640:text-4 pb-8"
							>
								{{ fundData.description }}
							</div>
						</div>
						<div class="mx1080:text-2 mx640:text-4" v-if="fundData.specs">
							<div class="border-b border-crab"></div>
							<div
								v-for="(item, index) in fundData.specs"
								:key="index"
								class="border-b border-crab py-4"
							>
								{{ item.spec }}
							</div>
						</div>
					</div>
				</div>
				<FundCarousel
					class="col-span-3 mx900:col-span-2"
					:slides="carouselData"
					@slideClick="handleSlideClick"
				/>
			</div>
		</div>

		<div class="who-we-are sun-home-wwa">
			<div class="mode-center">
				<div
					class="grid text-pine grid-cols-5 gap-20 pt-200 pb-40 mx640:pt-16 mx640:pb-16  mx900:grid-cols-2 mx900:gap-6 mx640:grid-cols-1 mx640:gap-12"
				>
					<div class="col-span-3 mx900:col-span-1">
						<img
							v-if="data_home_who_we_are.image"
							:src="data_home_who_we_are.image.md"
						/>
						<img
							src="@/assets/who-we-are-logo.svg"
							alt=""
							v-if="data_home_who_we_are.image == null"
						/>
					</div>
					<div class="anime-hover col-span-2 mx900:col-span-1">
						<div
							class="h1-text mx640:text-12 mx1080:text-4 pb-6"
							v-if="data_home_who_we_are.title"
						>
							{{ data_home_who_we_are.title }}
						</div>
						<div
							class="text-24 mn640px:leading-36 font-tiempos_r pb-10 mx640:text-6 mx1080:text-2.5"
							v-if="data_home_who_we_are.text"
							v-html="data_home_who_we_are.text"
						></div>
						<div v-if="data_home_who_we_are.button_link">
							<router-link
								:to="data_home_who_we_are.button_link"
								class="text-xl font-inter mx640:text-4"
							>
								{{ data_home_who_we_are.button_text }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="news bg-verbena">
			<div class="mode-center pt-140 pb-100">
				<div
					class="anime-hover flex justify-between items-center text-pine pb-100"
				>
					<h1 class="h1-text mx640:text-9 mx900:text-7">
						{{ hdr_latest_news }}
					</h1>
					<router-link
						:to="'/' + route_news"
						class="text-xl font-inter mx640:text-4"
					>
						{{ btn_news }}
					</router-link>
				</div>
				<div class="grid grid-cols-3 gap-6 mx640:grid-cols-1 mx900:grid-cols-2">
					<News
						v-for="card in homeNewsData.items.slice(0, 3)"
						:key="card.id"
						:card="card"
					/>
				</div>
			</div>
		</div>

		<GetInTouch />

		<!--  

		<div class="sign-up bg-mandarin">
			<div class="text-center py-16 w-1/2 my-0 mx-auto mx640:w-11/12">
				<div class="text-pine font-tiempos_r text-24 pb-10">
					{{ hdr_newsletter }}
				</div>
				<div class="form w-full inline-block text-xl">
					<form
						action="https://group.us16.list-manage.com/subscribe/post?u=22a9f69c2dcb7792e81e1c639&amp;id=8df76ccc64"
						method="post"
						id="mc-embedded-subscribe-form"
						name="mc-embedded-subscribe-form"
						target="_blank"
						novalidate
						class="flex justify-between border-b"
					>
						<input
							type="email"
							value=""
							name="EMAIL"
							id="mce-EMAIL"
							class="py-4 text-pine font-inter focus:outline-none w-full bg-mandarin placeholder-metal focus:placeholder-opacity-0"
							:placeholder="email_newsletter"
						/>
						<button
							type="submit"
							name="subscribe"
							id="mc-embedded-subscribe"
							class="text-pine nav-btn mr-0 font-inter focus:outline-none"
						>
							{{ btn_newsletter }}
						</button>
					</form>
				</div>
			</div>
		</div>
		
		--></div>
</template>

<script>
	import { EventBus } from "@/modules/event_bus";
	import { globals } from "@/modules/globals";
	import Step from "../components/ui/Step";
	import FundCarousel from "../components/ui/FundCarousel";
	import News from "../components/ui/News";
	import data from "@/data.json";
	import GetInTouch from "@/components/ui/GetInTouch.vue";

	export default {
		name: "Home",
		components: { Step, News, FundCarousel, GetInTouch },
		data() {
			return {
				tempData: data,
				homeNewsData: {
					items: [],
				},
				carouselData: null,
				selectedCarousel: 0,
				fundData: {},
				data_hero: {},
				data_what_we_do: {},
				data_funds: {},
				hdr_latest_news: globals.microcopy.hdr_latest_news[globals.language],
				btn_news: globals.microcopy.btn_news[globals.language],
				txt_capital: globals.microcopy.txt_capital[globals.language],
				txt_phase: globals.microcopy.txt_phase[globals.language],
				hdr_newsletter: globals.microcopy.hdr_newsletter[globals.language],
				email_newsletter: globals.microcopy.email_newsletter[globals.language],
				btn_newsletter: globals.microcopy.btn_newsletter[globals.language],
				route_news: globals.navigation["news"].slug,
				data_home_who_we_are: {},
			};
		},
		mounted() {
			//console.log(this.data_hero);
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 3;
			let fundCarouselData = null;
			let json_file_fundCarousel =
				"/json/funds/funds_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_fundCarousel)
				.then((response) => response.json())
				.then(function(data) {
					fundCarouselData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});
			let newsData = null;
			let json_file_news =
				"/json/news/news_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_news)
				.then((response) => response.json())
				.then(function(data) {
					newsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_home_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(newsData, sectionsData, fundCarouselData));
				}
			}
		},
		methods: {
			handleSlideClick(currentSlide) {
				this.selectedCarousel = currentSlide;
				this.getFundData();
			},
			getFundData() {
				this.fundData = this.carouselData[this.selectedCarousel];
			},
			setData(newsData, sectionsData, fundCarouselData) {
				this.setNewsData(newsData);
				this.setSectionsData(sectionsData);
				this.setCarouselData(fundCarouselData);
			},
			setCarouselData(data) {
				data.forEach(function(item, index) {
					item.specs = JSON.parse(item.specs);
				});
				//console.log(fundCarouselData)
				//console.log(data)
				this.carouselData = data;
				this.getFundData();
			},
			setNewsData(data) {
				this.homeNewsData = {
					items: data.items,
				};
			},
			setSectionsData(data) {
				//console.log(data)
				this.data_hero = data["hero"];
				this.data_what_we_do = data["what-we-do"];
				this.data_funds = data["funds"];
				this.data_home_who_we_are = data["home_who-we-are"];
			},
		},
	};
</script>

<style>
	/*
	.home-header {
		background: url("../assets/sun-2-gray.svg") no-repeat;
		background-size: 2300px 2600px;
		background-position-x: 20vw;
		background-position-y: -280px;
	}
	*/
	.home-header {
		background: url("../assets/sun-2-gray.svg") no-repeat;
		background-size: 2300px 2600px;
		background-position-x: 20vw;
		background-position-y: -400px;
		background-attachment: fixed;
	}

	@media only screen and (max-width: 640px) {
		.home-header {
			background-size: unset;
			background-position-y: 0;
			background-position: left;
		}
	}
	/*
	@media only screen and (min-width: 1600px) {
		.home-header {
			background-size: cover;
			background-position-y: 0;
			background-position-x: 0;
		}
	}
	*/

	.sun-hdr {
		background-image: url("../assets/sun/sun-hdr.svg");
		background-repeat: no-repeat;
		background-position: right top;
		background-size: cover;
	}

	.sun-big-up {
		background-image: url("../assets/sun/sun-big-up.svg");
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100vw;
	}
	.sun-big-down {
		background: url("../assets/sun/sun-big-down.svg");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100vw;
	}

	.sun-home-wwa {
		background: url("../assets/sun/sun-home-wwa.svg");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100vw;
		background-attachment: fixed;
	}
</style>
