<template>
	<div class="reletive">
		<div class="flex pb-5">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="fund_arrow mr-4 cursor-pointer"
				width="35"
				height="35"
				viewBox="0 0 35 35"
				@click="prevSlide"
			>
				<g fill="" fill-rule="evenodd">
					<g>
						<g transform="translate(-734 -2905) translate(734 2905)">
							<circle cx="17.5" cy="17.5" r="17.5" fill="" />
							<g>
								<g>
									<g>
										<path
											d="M6 10L12 16 18 10"
											transform="translate(5.5 5.5) rotate(180 12 12) rotate(-90 12 12)"
											class="fund_stroke"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
			<svg
				@click="nextSlide"
				class="fund_arrow cursor-pointer"
				xmlns="http://www.w3.org/2000/svg"
				width="35"
				height="35"
				viewBox="0 0 35 35"
			>
				<g fill="" fill-rule="evenodd">
					<g>
						<g transform="translate(-779 -2905) translate(779 2905)">
							<circle cx="17.5" cy="17.5" r="17.5" fill="" />
							<g>
								<g>
									<path
										class="fund_stroke"
										d="M6 10L12 16 18 10"
										transform="translate(5.5 5.5) rotate(-90 12 12)"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
		<vue-glide
			v-if="slides"
			class="demo"
			ref="slider"
			v-model="active"
			type="carousel"
			:perView="glideProps.perView"
			:gap="glideProps.gap"
			@glide:slide-click="handleSlideClick"
			:breakpoints="{
				900: {
					perView: 1,
				},
				1500: {
					perView: 2,
				},
			}"
			@gilde:mount-after="testMount"
		>
			<vue-glide-slide
				class="cursor-pointer"
				v-for="(slide, index) in slides"
				:key="index"
			>
				<div class="">
					<div class="bg-white relative h-450">
						<div v-if="slide.image">
							<img class="centery px-12 w-full" :src="slide.image.sm" alt="" />
						</div>
					</div>
					<div class="bg-verbena px-12 py-6 anime-hover">
						<router-link
							class="font-inter text-xl"
							:to="'/' + route_funds + '/' + slide.slug"
							>{{
								currentLanguage === "en" ? "Read more" : "Lees meer"
							}}</router-link
						>
					</div>
				</div>
			</vue-glide-slide>
		</vue-glide>
	</div>
</template>
<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "FundCarousel",
		data() {
			return {
				currentLanguage: globals.language,
				route_funds: globals.navigation["funds"].slug,
				active: 0,
				glideProps: {
					perView: 3,
					gap: 50,
				},
			};
		},
		props: ["slides"],
		methods: {
			handleSlideClick(index) {
				if (!Number.isNaN(index)) {
					this.active = index;
					this.$emit("slideClick", index);
				}
			},
			testMount() {
				// this.$refs.slider.glide.disable();
			},
			nextSlide() {
				this.$refs.slider.glide.go(">");
				this.$emit("slideClick", this.active);
			},
			prevSlide() {
				this.$refs.slider.glide.go("<");
				this.$emit("slideClick", this.active);
			},
		},
	};
</script>

<style scoped>
	.glide__slide {
		width: 400px;
	}
	.fund_arrow {
		fill: #faecec;
		transition: fill 0.4s ease;
	}
	.fund_arrow:hover {
		fill: #d6444a;
	}
	.fund_stroke {
		stroke: #383b41;
	}
	.fund_arrow:hover .fund_stroke {
		stroke: #fff;
	}
</style>
