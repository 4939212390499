<template>
	<div
		class="anime-hover grid grid-cols-10 gap-4 pb-10 mb-8 border-crab border-b w-11/12 font-tiempos_r last:border-b-0 mx900:w-full items-baseline"
	>
		<div class="col-span-1">
			<img v-if="!isInvestment" src="@/assets/what-we-do-logo.svg" alt="" />
			<div
				v-if="isInvestment"
				class="text-crab text-6xl font-national mx640:text-9"
			>
				{{ step.id + 1 }}.
			</div>
		</div>
		<div
			class="col-span-3 text-30 text mx1280:text-2.5 mx640:col-span-9 mx640:text-6"
		>
			{{ step.title }}
		</div>
		<div class="col-span-6 mx640:col-span-10 mx640:pt-10">
			<div
				class="mb-6 text-22 mx1280:text-2 mx640:text-4 rich-text"
				v-html="step.description"
			></div>
			<router-link
				v-if="!isInvestment"
				class="font-inter text-xl mx900:text-2 mx640:text-4"
				:to="step.button_link"
			>
				{{ step.button_text }}
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Step",
		props: ["step", "isInvestment"],
		data() {
			return {};
		},
		mounted() {},
	};
</script>

<style></style>
