var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reletive"},[_c('div',{staticClass:"flex pb-5"},[_c('svg',{staticClass:"fund_arrow mr-4 cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"35","height":"35","viewBox":"0 0 35 35"},on:{"click":_vm.prevSlide}},[_c('g',{attrs:{"fill":"","fill-rule":"evenodd"}},[_c('g',[_c('g',{attrs:{"transform":"translate(-734 -2905) translate(734 2905)"}},[_c('circle',{attrs:{"cx":"17.5","cy":"17.5","r":"17.5","fill":""}}),_c('g',[_c('g',[_c('g',[_c('path',{staticClass:"fund_stroke",attrs:{"d":"M6 10L12 16 18 10","transform":"translate(5.5 5.5) rotate(180 12 12) rotate(-90 12 12)"}})])])])])])])]),_c('svg',{staticClass:"fund_arrow cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"35","height":"35","viewBox":"0 0 35 35"},on:{"click":_vm.nextSlide}},[_c('g',{attrs:{"fill":"","fill-rule":"evenodd"}},[_c('g',[_c('g',{attrs:{"transform":"translate(-779 -2905) translate(779 2905)"}},[_c('circle',{attrs:{"cx":"17.5","cy":"17.5","r":"17.5","fill":""}}),_c('g',[_c('g',[_c('path',{staticClass:"fund_stroke",attrs:{"d":"M6 10L12 16 18 10","transform":"translate(5.5 5.5) rotate(-90 12 12)"}})])])])])])])]),(_vm.slides)?_c('vue-glide',{ref:"slider",staticClass:"demo",attrs:{"type":"carousel","perView":_vm.glideProps.perView,"gap":_vm.glideProps.gap,"breakpoints":{
			900: {
				perView: 1,
			},
			1500: {
				perView: 2,
			},
		}},on:{"glide:slide-click":_vm.handleSlideClick,"gilde:mount-after":_vm.testMount},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.slides),function(slide,index){return _c('vue-glide-slide',{key:index,staticClass:"cursor-pointer"},[_c('div',{},[_c('div',{staticClass:"bg-white relative h-450"},[(slide.image)?_c('div',[_c('img',{staticClass:"centery px-12 w-full",attrs:{"src":slide.image.sm,"alt":""}})]):_vm._e()]),_c('div',{staticClass:"bg-verbena px-12 py-6 anime-hover"},[_c('router-link',{staticClass:"font-inter text-xl",attrs:{"to":'/' + _vm.route_funds + '/' + slide.slug}},[_vm._v(_vm._s(_vm.currentLanguage === "en" ? "Read more" : "Lees meer"))])],1)])])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }